import * as React from "react";
import { Global, css } from "@emotion/core";

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        /* * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        body {
          font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
            "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
          letter-spacing: 0.1px;
          -webkit-font-smoothing: antialiased;
          padding-bottom: 14rem;
        }

        


        [style*="--aspect-ratio"] > :first-child {
          width: 100%;
        }
        [style*="--aspect-ratio"] > img {  
          height: auto;
        } 
        @supports (--custom:property) {
          [style*="--aspect-ratio"] {
            position: relative;
          }
          [style*="--aspect-ratio"]::before {
            content: "";
            display: block;
            padding-bottom: calc(100% / (var(--aspect-ratio)));
          }  
          [style*="--aspect-ratio"] > :first-child {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
          }  
        }
        
        */
      `}
    />
  );
};

export default GlobalStyles;
